<template>
  <b-card no-body class="decomposition-chart-card">
    <b-card-body>
      <div class="decomposition-chart" id="decomposition-plotly-chart" v-show="showChart" ref="chart"></div>
      <div class="decomposition-chart" v-show="!showChart">
        <EmptyChart :description="$t('tips_decomposition')" />
      </div>
      <ChartYearText ref="refChartYearText" v-if="showChart && $parent.$parent.annotations" :text="$parent.$parent.annotations" />
    </b-card-body>
  </b-card>
</template>

<script>
const _ = require('lodash')
import Plotly from 'plotly.js-dist'
import DownloadMixin from '@/mixins/DownloadMixin'
import { CustomLayout } from '@/mixins/ThemeMixin.js'
import EmptyChart from '../../common/EmptyChart.vue'
import ChartYearText from '../../common/ChartYearText.vue'
import i18n from '@/libs/i18n'
import { locale } from '@/scripts/plotly/locale.js'

const CONFIG = {
  displaylogo: false,
  responsive: true,
  editable: true,
  modeBarButtonsToRemove: ['toImage'],
}

export default {
  props: ['chart', 'nameComponent', 'chartFontDecomposition'],
  mixins: [DownloadMixin, CustomLayout],
  components: { EmptyChart, ChartYearText, locale },
  data() {
    return {
      funcEventPlotlyRelayout: null,
    }
  },
  mounted() {
    this.updateChart()

    // listen to div resize => chart resize
    new ResizeObserver(() => {
      if (this.$route.name == 'decomposition' && this.$refs.chart && this.$refs.chart.style && ((this.$refs.chart.style.display && this.$refs.chart.style.display !== 'none') || !this.$refs.chart.style.display)) {
        Plotly.Plots.resize(this.$refs.chart)
      }
    }).observe(this.$refs.chart)
  },
  computed: {
    showChart() {
      let show = false
      try {
        if (this.chart.data && Object.keys(this.chart.data || {}).length && this.chart.data.dates && this.chart.data.dates.length) {
          show = true
        }
      } catch {}
      return show
    },
    locale() {
      return i18n.locale
    },
    layoutChart() {
      return {
        showlegend: false,
        yaxis1: { automargin: true, domain: [0.76, 0.99], anchor: 'x1', title: this.$i18n.t('Original') },
        xaxis2: { automargin: true, anchor: 'y2' },
        yaxis2: { automargin: true, domain: [0.51, 0.74], anchor: 'x2', title: this.$i18n.t('Seasonal') },
        xaxis3: { automargin: true, anchor: 'y3' },
        yaxis3: { automargin: true, domain: [0.26, 0.49], anchor: 'x3', title: this.$i18n.t('Trend') },
        xaxis4: { automargin: true, anchor: 'y4' },
        yaxis4: { automargin: true, domain: [0.01, 0.24], anchor: 'x4', title: this.$i18n.t('Remainder') },
      }
    },
    chartLayout() {
      return this.$store.state.tabs.decomposition.chartLayout
    },
  },
  watch: {
    chart: {
      deep: true,
      handler() {
        this.updateChart(true)
      },
    },
    '$i18n.locale': function () {
      if (this.$route.name === 'decomposition') {
        this.updateChart()
      }
    },
    locale() {
      this.updateChart()
    },
    chartFontDecomposition: {
      deep: true,
      handler() {
        if (this.chartFontDecomposition) {
          this.updateChartLayout()
        }
      },
    },
    $route: {
      deep: true,
      handler() {
        try {
          if (this.$route.name == 'decomposition') {
            this.eventPlotlyLayout()
          }
        } catch {}
      },
    },
  },
  methods: {
    eventPlotlyLayout() {
      const self = this
      try {
        if (self.funcEventPlotlyRelayout) {
          clearTimeout(self.funcEventPlotlyRelayout)
        }
        self.funcEventPlotlyRelayout = setTimeout(() => {
          let plotlyElement = document.getElementById('decomposition-plotly-chart')
          if (plotlyElement) {
            plotlyElement.on('plotly_relayout', (data) => {
              try {
                let layoutOrigin = plotlyElement.layout
                let layoutTemp = {
                  xaxis: {},
                  xaxis2: {},
                  xaxis3: {},
                  xaxis4: {},
                  yaxis: {},
                  yaxis2: {},
                  yaxis3: {},
                  yaxis4: {},
                  yaxis5: {},
                  yaxis6: {},
                  yaxis7: {},
                  yaxis8: {},
                }
                if (layoutOrigin.title) {
                  layoutTemp.title = layoutOrigin.title
                }
                if (layoutOrigin.xaxis && layoutOrigin.xaxis.title) {
                  layoutTemp.xaxis.title = layoutOrigin.xaxis.title
                }
                if (layoutOrigin.xaxis2 && layoutOrigin.xaxis2.title) {
                  layoutTemp.xaxis2.title = layoutOrigin.xaxis2.title
                }
                if (layoutOrigin.xaxis3 && layoutOrigin.xaxis3.title) {
                  layoutTemp.xaxis3.title = layoutOrigin.xaxis3.title
                }
                if (layoutOrigin.xaxis4 && layoutOrigin.xaxis4.title) {
                  layoutTemp.xaxis4.title = layoutOrigin.xaxis4.title
                }
                if (layoutOrigin.yaxis && layoutOrigin.yaxis.title) {
                  layoutTemp.yaxis.title = layoutOrigin.yaxis.title
                }
                if (layoutOrigin.yaxis2 && layoutOrigin.yaxis2.title) {
                  layoutTemp.yaxis2.title = layoutOrigin.yaxis2.title
                }
                if (layoutOrigin.yaxis3 && layoutOrigin.yaxis3.title) {
                  layoutTemp.yaxis3.title = layoutOrigin.yaxis3.title
                }
                if (layoutOrigin.yaxis4 && layoutOrigin.yaxis4.title) {
                  layoutTemp.yaxis4.title = layoutOrigin.yaxis4.title
                }
                if (layoutOrigin.yaxis5 && layoutOrigin.yaxis5.title) {
                  layoutTemp.yaxis5.title = layoutOrigin.yaxis5.title
                }
                if (layoutOrigin.yaxis6 && layoutOrigin.yaxis6.title) {
                  layoutTemp.yaxis6.title = layoutOrigin.yaxis6.title
                }
                if (layoutOrigin.yaxis7 && layoutOrigin.yaxis7.title) {
                  layoutTemp.yaxis7.title = layoutOrigin.yaxis7.title
                }
                if (layoutOrigin.yaxis8 && layoutOrigin.yaxis8.title) {
                  layoutTemp.yaxis8.title = layoutOrigin.yaxis8.title
                }
                if (self.$route && self.$route.name == 'decomposition') {
                  self.$store.commit(`tabs/SET_DECOMPOSITION`, { chartLayout: layoutTemp })
                }
              } catch {}
            })
          }
        }, 1000)
      } catch {}
    },
    updateChart(isUpdate = false) {
      let _config = CONFIG
      _config.locale = i18n.locale
      let layoutTemp = {}
      try {
        if (this.chartFontDecomposition) {
          layoutTemp = _.merge(layoutTemp, { font: this.chartFontDecomposition })
        }
        if (this.chartLayout) {
          layoutTemp = _.merge(layoutTemp, this.chartLayout)
        }
      } catch {}
      if (isUpdate) {
        if (this.$refs.chart) {
          let layout = _.merge({}, this.$refs.chart.layout, this.themeLayout, this.layoutChart, this.chart.layout, layoutTemp)
          delete layout.xaxis
          // delete layout.yaxis;
          Plotly.react(this.$refs.chart, this.getData(), layout, _config)
        }
      } else {
        let layout = _.merge({}, this.themeLayout, this.layoutChart, this.chart.layout, layoutTemp)
        delete layout.xaxis
        delete layout.yaxis
        Plotly.newPlot(this.$refs.chart, this.getData(), layout, _config)
        this.eventPlotlyLayout()
      }
    },
    getData() {
      let data = this.chart.data || {}
      let original = {
        name: this.$i18n.t('Original'),
        x: data.dates || [],
        y: data.original || [],
        mode: 'lines',
        xaxis: 'x4',
        yaxis: 'y1',
        type: 'scatter',
        connectgaps: true,
      }
      let seasonal = {
        name: this.$i18n.t('Seasonal'),
        x: data.dates || [],
        y: data.seasonal || [],
        mode: 'lines',
        xaxis: 'x4',
        yaxis: 'y2',
        type: 'scatter',
        connectgaps: false,
      }
      let trend = {
        name: this.$i18n.t('Trend'),
        x: data.dates || [],
        y: data.trend || [],
        mode: 'lines',
        xaxis: 'x4',
        yaxis: 'y3',
        type: 'scatter',
        connectgaps: false,
      }
      let remainder = {
        name: this.$i18n.t('Remainder'),
        x: data.dates || [],
        y: data.remainder || [],
        mode: 'lines',
        xaxis: 'x4',
        yaxis: 'y4',
        type: 'scatter',
        connectgaps: false,
      }
      return [original, seasonal, trend, remainder]
    },
    updateChartLayout() {
      // setTimeout(() => {
      try {
        let layout = this.$refs.chart.layout
        let layoutTemp = {}
        try {
          if (this.chartFontDecomposition) {
            layoutTemp = _.merge(layoutTemp, { font: this.chartFontDecomposition })
          }
          if (this.chartLayout) {
            layoutTemp = _.merge(layoutTemp, this.chartLayout)
          }
        } catch {}

        layout = _.merge(layout, layoutTemp)
        Plotly.relayout(this.$refs.chart, layout)
        // Plotly.react(this.$refs.chart, this.$refs.chart.data, layout);
      } catch {}
      // }, 300);
    },
  },
}
</script>

<style scoped>
.decomposition-chart-card .card-body {
  padding-top: 0;
}

.decomposition-chart {
  height: calc(100vh - 30px - 90.3px - 90px - 2rem - 36px);
}
</style>
